
import { Component, Prop, Watch } from 'vue-property-decorator';
import ListToolbar from '@/shared/components/layout/list/list-toolbar.vue';
import ListComponent from '@/shared/components/layout/list/list-component';
import { ModuleState } from '@/shared/state/template/module-state';
import { DataRequest, ItemData, ListFilter } from '@/shared/types';
import { ErrorBoundary } from '@/shared/components';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
import FilterBar from '@/shared/components/layout/list/filter-bar.vue';
import { ToolbarProps } from '@/shared/models/toolbar-props';
import { ModuleProps } from '@/shared/state/template/module-props';
import { FooterProps } from '@/shared/components/layout/list/models/footer-props';
import NameReduce from '@/shared/components/elements/name-reduce.vue';
import TooltipBtn from '../elements/tooltip-btn.vue';

@Component({
    components: {
        FilterBar,
        ListToolbar,
        ActionConfirmDialog,
        ErrorBoundary,
        NameReduce,
        TooltipBtn,
    },
    props: {
        keyShortcut: {
            type: Array,
            default: () => ['ctrl', 'alt', 'n'],
        },
        filterConfig: Array,
        hideDefaultHeader: Boolean,
        hideDefaultFooter: Boolean,
        props: Object,
        parentId: Number,
        fetchAction: String,
        showSelect: Boolean,
        headers: Array,
        removeWarning: String,
        loadingList: Boolean,
        hasCustomRelatedDialog: Boolean,
        footerOptions: {
            type: Object,
            default: () => new FooterProps(),
        },
        value: Array,
        loadingFilters: Boolean,
        toolbarProps: {
            type: Object,
            default: () => new ToolbarProps(),
        },
        itemShow: {
            type: Boolean,
            default: true,
        },
        hideSearch: Boolean,
        toolbar: {
            default: true,
            type: Boolean,
        },
        itemClass: [Array, Function],
        customCreateItem: Boolean,
        mutationTypes: Object,
        itemName: {
            default: 'name',
            type: String,
        },
        formWidth: {
            default: '900px',
            type: String,
        },
        detailsWidth: {
            default: '600px',
            type: String,
        },
        readonly: Boolean,
        removeAction: String,
        formDialogOptions: Object,
        // searchHeaders: Array  - Do odkomentowania jeśli będzie się dało przekazać nagłówki,
        // po których można szukać w wyszukiwarce
    },
})
export default class ItemsTable<T extends ItemData, R extends DataRequest> extends ListComponent<T, R> {
    @Prop(Boolean) public customFilterBar!: boolean;
    @Prop(Boolean) public hasOtherPermissions!: boolean;
    @Prop(String) public customPermissionBaseNames!: string;
    @Prop({ default: false }) public disableDelete!: boolean;
    @Prop(String) public disableDeleteMessage!: string;
    @Prop(Boolean) public removeDisabled!: boolean;
    @Prop(Object) public defaultFilters!: any;
    @Prop({
        type: Array,
        default() {
            return [];
        },
    })
    public additionalPermissionBaseNames!: string[];
    @Prop({
        type: Array,
        default() {
            return [];
        },
    })
    public customCreatePermissions!: string[];
    @Prop({ type: String, default: '600px' }) public detailsMaxWidth!: string;
    public filterConfig!: ListFilter[];
    public parentId!: number;
    public footerOptions!: FooterProps;
    public value!: T[];
    public footer: FooterProps = new FooterProps();
    public destroyObject = this.props.hasParent;
    public customCreateItem!: boolean;
    public hideDefaultFooter!: boolean;

    get selectedItems(): T[] {
        return this.value;
    }

    set selectedItems(value) {
        this.$emit('input', value);
    }

    get footerProps() {
        this.footer.disableItemsPerPage = this.paginate || this.loading;
        this.footer.disablePagination = this.paginate || this.loading;
        return this.footer;
    }

    // @ts-ignore
    get store() {
        if (this.props && !this.props.parents) {
            return this.$store.state[this.props.stateName];
        } else if (this.$store.state[this.props.parentStateName]) {
            return this.$store.state[this.props.parentStateName][this.props.stateName];
        }
        return undefined;
    }

    set store(val) {
        if (this.props && !this.props.parents) {
            this.$set(this.$store.state, this.props.stateName, val);
        } else if (this.$store.state[this.props.parentStateName]) {
            this.$set(this.$store.state[this.props.parentStateName], this.props.stateName, val);
        }
    }

    public created() {
        this.footer = this.footerOptions;
        this.$nextTick(() => {
            this.id = this.parentId;
            if (this.defaultFilters) {
                this.fetchParams.filters = this.defaultFilters;
            } else {
                this.fetchParams.filters = {};
            }

            if (this.props.loadListOnCreate) {
                this.fetchData();
            }
        });
    }

    public isPermitted(actionName: string) {
        const permissions = [
            ...this.props.permissionBaseNames.map((baseName) => `${baseName}.${actionName}`),
            ...this.additionalPermissionBaseNames.map((baseName) => `${baseName}.${actionName}`),
        ];

        return this.permissionCheck(...permissions);
    }

    public createItem(item?: R) {
        if (this.customCreateItem) {
            this.$emit('create-item');
        } else {
            this.setDefaultData(item || ({} as R));
            this.store.formDialog = true;
        }
    }

    public itemSaved() {
        this.formDialog = false;

        if (!this.edit && !this.search && !this.hideDefaultFooter) {
            const futurePage = Math.ceil((this.total + 1) / this.per_page);
            this.page = futurePage > this.last_page ? this.last_page + 1 : this.last_page;
        }

        if (this.main) {
            this.setDefaultData();
            this.fetchData();
        } else {
            this.$emit('itemChanged');
        }
    }

    public changePage() {
        return;
    }
}
