
    import {Component} from 'vue-property-decorator';
    import { callSuper, phraseInitials} from '@/shared/helpers';
    import FilterComponent from '@/shared/components/layout/list/filter-bar/filter-component';

    @Component({
        props: {
            option: Object,
            filters: Array,
        },
    })
    export default class FilterDate extends FilterComponent {
        public startDate: string = '';
        public endDate: string = '';

        get label() {
            if (this.selected.length > 1) {
                let label = phraseInitials(this.option.label) + ':';
                const start = this.selected[0] !== '' ? this.selected[0] : '';
                const end = this.selected[1] !== '' ? this.selected[1] : '';

                label += start !== '1970-01-01' ? ' ' + start : '';
                label += end !== '2050-01-01' ? ' - ' + end : '+';

                return label;
            }

            return this.option.label;
        }

        get active() {
            return this.startDate.trim() !== '' || this.endDate.trim() !== '';
        }


        public setSelected() {
            const start = this.filters && this.filters.length > 0 ? this.filters[0] : '';
            const end = this.filters && this.filters.length > 1 ? this.filters[1] : '';

            this.startDate = start !== '1970-01-01' ? start : '';
            this.endDate = end !== '2050-01-01' ? end : '';
        }

        public saveFilers() {
            const start = this.startDate ? this.startDate : '1970-01-01';
            const end = this.endDate ? this.endDate : '2050-01-01';

            this.selected = this.active ? [start, end] : [];
            callSuper(this, 'saveFilers', );
        }

        public clearFilters() {
            this.startDate = '';
            this.endDate = '';
            callSuper(this, 'clearFilters', );
        }
    }
