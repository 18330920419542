
import {Component} from 'vue-property-decorator';
import FilterComponent from '@/shared/components/layout/list/filter-bar/filter-component';
import {SelectListItem} from '@/shared/models/select-list-item';
import {contrast, hexToRgb} from '@/shared/helpers/colors';
import { callSuper } from '@/shared/helpers';

@Component({
    props: {
        option: Object,
        filters: Array,
    },
})
export default class FilterSearch extends FilterComponent {
    public selectedChips: SelectListItem[] = [];
    public page: number = 1;
    public search: string = '';
    public whiteColor = hexToRgb('#ffffff');
    public blackColor = hexToRgb('#000000');

    public isDark(item: string) {
        return contrast(hexToRgb(item), this.whiteColor) > 2;
    }

    public isLight(item: string) {
        return contrast(hexToRgb(item), this.blackColor) > 2;
    }

    get label() {
        return this.selected.length > 0 ?
            this.localOption.label + ' - ' + this.selected.length : this.localOption.label;
    }

    get active() {
        return this.selected.length > 0;
    }



    public setSelected() {
        this.selected = this.filters ? this.filters : [];
        this.selectedChips = this.filters ?
            this.option.data.filter((chip: SelectListItem) => this.filters.includes(String(chip.id))) :
            [];
    }

    public clearFilters() {

        this.selectedChips = [];

        callSuper(this, 'clearFilters', );
    }

    public onFiltersChange(oldVal: any, newVal: any) {
        if (!this.filters) {
            this.selectedChips = [];
        }
        callSuper(this, 'onFiltersChange', oldVal, newVal);
    }

    public alreadySet(item: SelectListItem) {
        return this.selectedChips.includes(item);
    }

    public saveFilers() {
        this.selected = this.selectedChips.map((item) => String(item.id));

        callSuper(this, 'saveFilers', );
    }
}
