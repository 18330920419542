
import { Component, Vue, Watch } from 'vue-property-decorator';
import FilterSelect from '@/shared/components/layout/list/filter-bar/filter-select.vue';
import FilterDate from '@/shared/components/layout/list/filter-bar/filter-date.vue';
import FilterRange from '@/shared/components/layout/list/filter-bar/filter-range.vue';
import FilterSwitch from '@/shared/components/layout/list/filter-bar/filter-switch.vue';
import FilterSearch from '@/shared/components/layout/list/filter-bar/filter-search.vue';

@Component({
    props: {
        options: Array,
        loading: Boolean,
    },
    components: {
        FilterSelect,
        FilterSearch,
        FilterDate,
        FilterRange,
        FilterSwitch,
    },
})
export default class FilterBar extends Vue {
    public options!: object[];
    public loading!: boolean;
    public filters: any = {};

    public setFilters(filter: any) {
        const newFilters = Object.assign({}, this.filters);

        newFilters[filter.name] = filter.data;

        this.filters = newFilters;
        this.$emit('filtersChanged', this.filters);
    }

    public clearFilters() {
        const newFilters = Object.assign({}, this.filters);

        for (const field in this.filters) {
            if (this.filters.hasOwnProperty(field)) {
                newFilters[field] = null;
            }
        }

        this.filters = newFilters;

        this.$emit('filtersChanged', this.filters);
    }

    @Watch('$route.query', { deep: true, immediate: true })
    public onRouteParamsChange(queryParams: any) {
        for (const [key, value] of Object.entries(queryParams)) {
            const [obj, prop] = key.split('.');
            if (obj === 'filters' && prop) {
                const filter = {
                    name: prop,
                    data: value,
                };
                this.setFilters(filter);
            }
        }
    }
}
