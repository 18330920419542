
import {Component} from 'vue-property-decorator';
import FilterComponent from '@/shared/components/layout/list/filter-bar/filter-component';
import { callSuper } from '@/shared/helpers';

@Component({
        props: {
            option: Object,
            filters: Array,
        },
    })
    export default class FilterSelect extends FilterComponent {
        get label() {
            return this.selected.length > 0 ?
                this.localOption.label + ' - ' + this.selected.length : this.localOption.label;
        }

        get active() {
            if (this.localOption && this.localOption.data) {
                for (const field of this.localOption.data) {
                    if (field.value) {
                        return true;
                    }
                }
            }

            return false;
        }


        public setSelected() {
            this.selected = [];

            if (this.localOption && this.localOption.data) {
                for (const field of this.localOption.data) {
                    if (this.filters && this.filters.includes(field.name)) {
                        field.value = true;
                        this.selected.push(field.name);
                    } else {
                        field.value = false;
                    }
                }
            }
        }

        public clearFilters() {

            if (this.localOption && this.localOption.data) {
                for (const field of this.localOption.data) {
                    field.value = false;
                }
            }

            callSuper(this, 'clearFilters', );
        }

        public saveFilers() {
            this.selected = [];

            if (this.localOption && this.localOption.data) {
                for (const field of this.localOption.data) {
                    if (field.value) {
                        this.selected.push(field.name);
                    }
                }
            }

            callSuper(this, 'saveFilers', );
        }
    }
