
import {Component} from 'vue-property-decorator';
import FilterComponent from '@/shared/components/layout/list/filter-bar/filter-component';
import { callSuper } from '@/shared/helpers';

@Component({
        props: {
            option: Object,
            filters: Array,
        },
    })

    export default class FilterSwitch extends FilterComponent {
        public switchCheckbox: boolean = false;



        public setSelected() {
            const checked = this.filters && this.filters.length > 0 ? this.filters[0] : false;
            this.switchCheckbox = !!checked;
        }

        public saveFilers() {
            this.selected = this.switchCheckbox ? ['1'] : [];
            callSuper(this, 'saveFilers', );
        }

        public clearFilters() {
            this.switchCheckbox = false;
            callSuper(this, 'clearFilters', );
        }
    }
