
import {Component} from 'vue-property-decorator';
import {callSuper, phraseInitials} from '@/shared/helpers';
import FilterComponent from '@/shared/components/layout/list/filter-bar/filter-component';

@Component({
    props: {
        option: Object,
        filters: Array,
    },
})
export default class FilterRange extends FilterComponent {
    public startNumber: string = '';
    public endNumber: string = '';

    get label() {
        if (this.selected.length > 1) {
            let label = phraseInitials(this.option.label) + ':';

            label += this.selected[0] !== '' ? ' ' + this.selected[0] : '0';
            label += this.selected[1] !== '' ? (this.selected[1] === '999999999' ? '+' : ' - ' + this.selected[1]) : '';

            return label;
        }

        return this.option.label;
    }

    get active() {
        return this.startNumber.trim() !== '' || this.endNumber.trim() !== '';
    }



    public setSelected() {
        this.startNumber = this.filters && this.filters.length > 0 ? this.filters[0] : '';
        this.endNumber = this.filters && this.filters.length > 1 && this.filters[1] !== '999999999' ?
            this.filters[1] : '';
    }

    public saveFilers() {
        const start = this.startNumber ? this.startNumber : '0';
        const end = this.endNumber ? this.endNumber : '999999999';

        this.selected = this.active ? [start, end] : [];
        callSuper(this, 'saveFilers', );
    }

    public clearFilters() {
        this.startNumber = '';
        this.endNumber = '';
        callSuper(this, 'clearFilters', );
    }
}
