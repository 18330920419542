import {Component, Vue, Watch} from 'vue-property-decorator';

@Component
export default class FilterComponent extends Vue {
    protected filters!: string[];
    protected selected: string[] = [];
    protected show: boolean = false;
    protected option!: any;
    protected localOption: any = {};

    public  setSelected() {
        //
    }

    @Watch('show')
    public onShowChange() {
        this.setSelected();
    }

    @Watch('filters', { deep: true })
    public onFiltersChange(oldVal: any, newVal: any) {
        if (oldVal === newVal) {
            return;
        }

        this.selected = this.filters && this.filters.length > 0 ? this.filters : [];
        this.setSelected();
    }

    public created() {
        this.localOption = this.option;
        this.setSelected();
    }

    public saveFilers() {
        this.show = false;
        this.$emit('filterChanged', { name: this.option.name, data: this.selected });
    }

    public clearFilters() {
        this.show = false;
        this.selected = [];
        this.$emit('filterChanged', { name: this.option.name, data: this.selected });
    }
}
